<template>

  <div>
    <!--- loading state -->
    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>
    <!-- data section -->
    <template v-else>

      <!-- error section -->
      <template v-if="aplicacionData.length == 0">

        <b-alert
            variant="danger"
            show
        >
          <h4 class="alert-heading">Error al obtener los datos del sistema</h4>
          <div class="alert-body">
            <h6>
              El sistema correspondiente a este ID no ha sido encontrado. Por favor, revise la lista de sistemas para ver otras opciones.
            </h6>
            <b-button
                variant="primary"
                @click="handleBack"
            >
              Volver a la lista
            </b-button>
          </div>



        </b-alert>

      </template>

      <!-- data  -->
      <template v-else>

        <!-- modo edicion -->
        <template v-if="isEditMode">

          <aplicacion-edit
              :aplicacion-prop="sistemaDataProp"
              @closeEditMode="handleEdit(false)"
              @successEditMode="handleSuccessEdit( false )"
          />

        </template>

        <!-- modo lectura -->

        <template v-else>

          <!-- info user -->

        <b-card>

          <!-- back -->
          <b-row>
            <b-col>
              <b-button
                  variant="link"
                  class="p-0"
                  @click="handleBack"
              >
                <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">Regresar</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <h3>{{ aplicacionData.sysNombre }}</h3>
              <span>{{ aplicacionData.sysDescripcion }}</span>
            </b-col>
          </b-row>

          <b-row class="mt-2">

            <b-col cols="12">

              <table>

                <tr>
                  <th>
                    <span class="font-weight-bold mr-50">Estado</span>
                  </th>
                  <td>
                    <div class="text-nowrap">
                      <feather-icon
                          icon="CheckIcon"
                          size="18"
                          class="mr-50"
                          :class="`text-${getStatusVariant(aplicacionData.sysEstado)}`"
                      />
                      <span class="align-text-top text-capitalize">{{ getStatusName( aplicacionData.sysEstado )  }}</span>
                    </div>
                  </td>
                </tr>


              </table>

            </b-col>

          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                  variant="primary"
                  @click="handleEdit( true )"
              >
                Editar
              </b-button>
            </b-col>
          </b-row>

        </b-card>

        <!-- danger zone -->
        <b-card title="Zona de riesgo">
          ¡Atención! Está a punto de eliminar una aplicación de forma permanente. Esta acción no puede deshacerse. Por favor, asegúrese de que desea continuar antes de hacer clic en el botón "Eliminar".
          <b-row class="mt-2">
            <b-col>
              <b-button
                  variant="danger"
                  @click="handleDelete"
                  :disabled="isBusy"
              >
                <template v-if="isBusy">
                  <b-spinner small />
                  Eliminando...
                </template>
                <template v-else>
                  Eliminar aplicación
                </template>
              </b-button>

            </b-col>
          </b-row>
        </b-card>

      </template>

      </template>


    </template>

  </div>

</template>

<script>

import {
  BAlert,
  BLink,
  BSpinner,
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol
} from 'bootstrap-vue'

import { mapActions } from 'vuex'
import { resolveStatusName, resolveStatusVariant } from '@/modules/common/utils'
import { StarIcon } from 'vue-feather-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { deleteAplicacion } from '@/modules/ejecutante/store/aplicaciones/actions'

export default {
  name: 'AplicacionView',
  components: {
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,

    AplicacionEdit: () => import('@/modules/ejecutante/views/catalogos/aplicaciones/AplicacionEdit.vue')

  },

  data(){
    return {

      isLoading: false,
      isEditMode: false,
      isBusy: false,
      aplicacionData: []

    }
  },

  computed:{
    StarIcon() {
      return StarIcon
    },

    queryUuid(){

      const { uuid } = this.$route.query

      return uuid ?? null

    },

    sistemaDataProp(){
      return { ...this.aplicacionData }
    }
  },

  methods:{

    ...mapActions( 'aplicacion-module', ['findOne', 'deleteAplicacion']  ),

    async loadData(){

      try {

        this.aplicacionData = []

        this.isLoading = true

        const  data  = await this.findOne( { 'uuid': this.queryUuid } )

        this.aplicacionData = data

        this.isLoading = false

      } catch ( error ) {




      } finally {
        this.isLoading = false
      }

    },

    // redireccionar a la lista
    handleBack(){
      this.$router.push({name: 'catalogo-aplicaciones'})
    },

    // procesar accion edit
    handleEdit( editMode ){
      this.isEditMode = editMode
    },

    // procesar accion edit exitosa
    async handleSuccessEdit( editMode ){
      await this.loadData()
      this.isEditMode = editMode
    },

    // procesar accion delete
    async handleDelete(){

      const isConfirm = await  this.$bvModal.msgBoxConfirm(
          '¿Estás seguro que deseas eliminar esta aplicación? Esta acción es irreversible y eliminará toda su información.',
          {
            title: 'Eliminar aplicación',
            okVariant: 'danger',
            okTitle: 'Sí, eliminar aplicación',
            cancelTitle: 'No',
            cancelVariant: 'secondary',
            modalClass: "modal-danger",
            hideHeaderClose: false,
            centered: true,
          })

      if ( ! isConfirm )  return

      try {

        this.isBusy = true

        this.deleteAplicacion( this.aplicacionData.sysUuid)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Operación exitosa',
            icon: 'CheckIcon',
            text: 'La eliminación de la aplicación se ha realizado correctamente.',
            variant: 'success'
          },
        })

        this.isBusy = false

        this.$router.push({name: 'catalogo-aplicaciones'})

      } catch ( error ) {

      }


    },



    getStatusVariant( status ){
      return resolveStatusVariant( status )
    },

    getStatusName( status ) {
      return resolveStatusName( status )
    },

  },

  async created() {
    await this.loadData()
  }

}

</script>

<style scoped>

</style>
